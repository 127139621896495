let {infoConfig, loginUserModel} = window.platformData() || {};
let globalData = window.globalData() || {};

let globalMixin = {
    data () {
        return {
            infoConfig,
            loginUserModel,
            globalData
        };
    },
    computed: {
        isShowPrice () {
            if (this.globalData) {
                return this.globalData.isShowPrice;
            }
        },
        isShowCart () {
            if (this.globalData) {
                return this.globalData.isShowCart;
            }
        },
        isSearch () {
            if (this.globalData) {
                return this.globalData.isSearch;
            }
            return false
        },
        isPurchase () {
            if (this.globalData) {
                return this.globalData.isPurchase;
            }
        },
        isLogged () {
            return !!this.loginUserModel;
        },
        isStudent () {
            return 'STUDENT' === this.loginUserModel.userType.code;
        },
        isTeacher () {
            return 'TEACHER' === this.loginUserModel.userType.code;
        },
        pageUri () {
            return window.location.pathname;
        },
        pageQuery () {
            let search = window.location.search;
            let query = {};
            if (search) {
                if (search.startsWith('?')) {
                    search = search.substring(1);
                }
                let params = search.split('&');
                params.forEach(param => {
                    let split = param.indexOf('=');
                    if (split > 0) {
                        let key = param.substring(0, split);
                        let val = decodeURIComponent(param.substring(split + 1));
                        let value;
                        if (query.hasOwnProperty(key)) {
                            value = query[key];
                            if (!Array.isArray(value)) {
                                value = [value];
                            }
                            value.push(val);
                        } else {
                            value = val;
                        }
                        query[key] = value;
                    }
                });
            }
            return query;
        },
        pageHash () {
            let hash = window.location.hash;
            if (hash) {
                if (hash.startsWith('#')) {
                    hash = hash.substring(1);
                }
                return hash;
            }
        },
        menuMap () {
            return this.globalData.menuMap;
        }
    },
    methods: {
        toUrl (location) {
            window.location.href = location;
        },
        reloadUrl () {
            window.location.reload();
        },
        loginResolve () {
            return new Promise((resolve, reject) => {
                if (this.isLogged) {
                    if (this.isStudent || this.isTeacher) {
                        resolve();
                    } else {
                        this.$message.warning('您没有访问该功能的权限');
                    }

                } else {
                    let headVm = window.HeadVm;
                    if (headVm) {
                        headVm.onUserLogin(isOk => {
                            if (isOk) {
                                // 登录成功后会刷新页面
                            } else {
                                reject(new Error('用户取消登录'));
                            }
                        });
                    } else {
                        reject(new Error('头部组件未完成初始化'));
                    }
                }
            });
        },
        openUrl (location) {
            window.open(location);
        }
    }
};
